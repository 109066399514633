$(document).ready(function () {
	var $categories = $('.categories'),
	    $categoriesList = $categories.find('ul'),
	    $toggleCategoriesBtn = $categories.find('.toggle-categories-btn');

			// adding specific sanitizing attributes to prevent html injections
	var DOMPurifyConfig = {
            USE_PROFILES: [{html: false, svg: false, svgFilters: false, mathMl: false}]
            };

	//make the title click-able
	$categories.find('h3').on('click', function () {
		$toggleCategoriesBtn.trigger('click');
	});

	//show/hide the categories when button is clicked
	$toggleCategoriesBtn.on('click', function () {
		if ($categoriesList.is(':visible')) {
			$categoriesList.slideUp();
			$toggleCategoriesBtn.removeClass('blue-downarrow-12').addClass('blue-uparrow-12').text($toggleCategoriesBtn.data('open-text'));
		} else {
			$categoriesList.slideDown();
			$toggleCategoriesBtn.removeClass('blue-uparrow-12').addClass('blue-downarrow-12').text($toggleCategoriesBtn.data('close-text'));
		}
	});

	// elements to be used in the search functions
	var swiftypeEngineKey,
	    $siteSearchField = $('#site-search-field'),
	    $resultItemTpl = $('#result-item-tpl').html(),
	    $resultSummaryTpl = $('#result-summary-tpl').html(),
	    $categoryFacetTpl = $('#category-facet-tpl').html(),
	    $noResultSummaryTpl = $('#no-result-summary-tpl').html(),
	    $resultSummary = $('.result-summary'),
	    $resultsContainer = $('#results-container'),
	    $categoryContainer = $('#category-facets'),
	    reloadResults = function() {
		    $(window).hashchange();
		};

	if (typeof localeData === 'object') {
		// load localized search engine key with location is known
		switch (localeData.status) {
			case 'AU':
			case 'NZ':
				swiftypeEngineKey = '1mL-GhupWd21NdDfrv4L'; //princess-dot-com-au-slash-nz
				break;
			case 'UK':
				swiftypeEngineKey = 'ak7AKr-c_z49fWhPrBjg'; // princess-dot-com-uk
				break;
			case 'SG':
				swiftypeEngineKey = 'eC1F7mt9kubnEsmYvQmF'; // princess-dot-com-uk
				break;
			case 'HK':
				swiftypeEngineKey = 'bVnC9LQ4VWHYKYBScsAz'; // princess-dot-com-uk
				break;
			case 'IN':
				swiftypeEngineKey = 'f66xfTafWxT9dn4wiahZ'; // princess-dot-com-uk
				break;
			case 'US':
			default:
				swiftypeEngineKey = 'yetysyoKNGa-z34iY88M'; //princess-dot-com-AEM
				break;
		}
	} else {
		// default to US search engine when location is not known
		swiftypeEngineKey = 'yetysyoKNGa-z34iY88M'; //princess-dot-com-v2
	}

	window.searchConfig = {
		facets: {}
	};

	//parsing templates in Mustache for improved performance
	Mustache.parse($resultItemTpl);
	Mustache.parse($resultSummaryTpl);
	Mustache.parse($categoryFacetTpl);
	Mustache.parse($noResultSummaryTpl);

	// search results controls
	var renderSummaryCategory = function(data) {

		var resultInfo = data['info'],
		    summaryData = {},
		    facetData = {'facets': []},
		    displaySummary;

		if(typeof resultInfo === 'object' && resultInfo.page!== 'undefined'){
				window.digitalData = window.digitalData || {};
				window.digitalData.search = window.digitalData.search || {};
				window.digitalData.search.term = resultInfo.page.query;
				window.digitalData.search.source = 'site-search';
		}

		if (resultInfo.page['total_result_count'] === 0) {
			var totalResultCount = 0,
			    $resultContainer = this.getContext().resultContainer;
			    spellingSuggestion = null;

			$categoryContainer.closest('.categories').addClass('hidden');

			if (data['info']) {
				$.each(data['info'], function(index, value) {
					totalResultCount += DOMPurify.sanitize(value['total_result_count']);
					if ( value['spelling_suggestion'] ) {
						summaryData['spellingSuggestion'] = DOMPurify.sanitize(value['spelling_suggestion']['text'], DOMPurifyConfig);
					}
				});
			}

			displaySummary = Mustache.render($noResultSummaryTpl,summaryData);
			$('.result-summary').html(displaySummary);
		} else {
			// construct the summary data for client side templating
			summaryData['resultFrom'] = function () {
				return DOMPurify.sanitize((resultInfo.page['current_page']-1) * resultInfo.page['per_page'] + 1);
			}
			summaryData['resultTo'] = function () {
				return DOMPurify.sanitize((resultInfo.page['current_page']-1) * resultInfo.page['per_page'] + data.record_count);
			}
			summaryData['query'] = DOMPurify.sanitize(resultInfo.page['query'], DOMPurifyConfig);
			summaryData['totalResultCount'] = DOMPurify.sanitize(resultInfo.page['total_result_count']);
			summaryData['category'] = function () {
				if (typeof window.searchConfig.facets.category != 'undefined') {
					var facetsCategory = window.searchConfig.facets.category,
					    categoriesArr = [];

					// add a comma to separate the category list
					for (var c = 0; c < facetsCategory.length; c++) {
						if (c < facetsCategory.length - 1) {
							categoriesArr[c] = facetsCategory[c] + ', ';
						} else {
							categoriesArr[c] = facetsCategory[c];
						}
					}
					return DOMPurify.sanitize(categoriesArr);
				}
			}

			// display results summary info in the page
			displaySummary = Mustache.render($resultSummaryTpl,summaryData);
			$('.result-summary').html(displaySummary);

			// construct the facets data for client side templating
			$.each(resultInfo, function(documentType, typeInfo){
				$.each(typeInfo.facets, function(field, facetCounts) {
					$.each(facetCounts, function(label, count) {
						var omitFacets = ['Video','video','videogallery','Photo','photo','photogallery','Panoramic'];
						if (jQuery.inArray(label, omitFacets ) < 0) {
							var facetOpt = {};
							let displayLabel = label;
							facetOpt['id'] = DOMPurify.sanitize(encodeURIComponent(label).toLowerCase());
							facetOpt['label'] = DOMPurify.sanitize(label);
							facetOpt['displayLabel'] = DOMPurify.sanitize(displayLabel).replace('princess:', '').replaceAll('-', ' ');
							facetOpt['count'] = DOMPurify.sanitize(count);
							if (window.searchConfig.facets[field] && window.searchConfig.facets[field].indexOf(label) > -1) {
								facetOpt['status'] = true;
							} else {
								facetOpt['status'] = false;
							}
							facetData.facets.push(facetOpt);
						}
					});
				});
				// if we have multiple category types, we would populate the options here using another loop similar to above
			});

			// display facet options in the page
			var displayFacets = Mustache.render($categoryFacetTpl,facetData);
			$categoryContainer.html(displayFacets).closest('.categories').removeClass('hidden');
		}

		//show hidden components, and hide search placeholder
		$('.topics, .results').removeClass('hidden');
		$('#search-placeholder').addClass('hidden');
    };

	var readFilters = function() {
		return {
			page: {
				category: window.searchConfig.facets['category']
			}
		}
	}

	var monthNames = [
		{
			'longname':'January',
			'shortname': 'Jan',
		},
		{
			'longname':'February',
			'shortname':'Feb'
		},
		{
			'longname':'March',
			'shortname':'Mar'
		},
		{
			'longname':'April',
			'shortname':'Apr'
		},
		{
			'longname':'May',
			'shortname':'May'
		},
		{
			'longname':'June',
			'shortname':'Jun'
		},
		{
			'longname':'July',
			'shortname':'Jul'
		},
		{
			'longname':'August',
			'shortname':'Aug'
		},
		{
			'longname':'September',
			'shortname':'Sep'
		},
		{
			'longname':'October',
			'shortname':'Oct'
		},
		{
			'longname':'November',
			'shortname':'Nov'
		},
		{
			'longname':'December',
			'shortname':'Dec'
		}
	];

	// merging the result data with the
	var renderSearchResults = function(document_type, item) {
		// by default, all pages will have title, description, and a link
		var data = {
			url: item.url,
			id: item.id
		};

		// define title
		if (typeof item.highlight.title != 'undefined') {
			// it's preferred to display the hightlight.title
		 	data['title'] = DOMPurify.sanitize(item.highlight.title);
		} else {
			// display plain title as a backup
			data['title'] = DOMPurify.sanitize(item.title);
		}

		// define section
		if (typeof item.highlight.title != 'undefined') {
			// it's preferred to display the hightlight.section
		 	data['section'] = DOMPurify.sanitize(item.highlight.sections);
		} else {
			// display plain section as a backup
			data['section'] = DOMPurify.sanitize(item.sections[0]);
		}

		// define description text
		if (typeof item.highlight.body != 'undefined') {
			// it's preferred to display the hightlight.body
			data['desc'] = DOMPurify.sanitize(item.highlight.body);
		} else {
			// display truncated plain body as a backup
			var bodyText = item.body,
			    characterLimit = 150;
			// if the body text is over the characterLimit, find the next period (.) after the characterLimit, then truncate the bodyText
			if (bodyText.length > characterLimit) {
				var extraText = bodyText.substring(characterLimit, bodyText.length),
				    endOfCurrentSentence = extraText.indexOf('.') + 1,
				    shortenBodyText = bodyText.substring(0, (characterLimit + endOfCurrentSentence));

				data['desc'] = DOMPurify.sanitize(shortenBodyText);
			} else {
				data['desc'] = DOMPurify.sanitize(bodyText);
			}
		}

		// for promo pages, include the promo end date
		if (typeof item.promo_enddate != 'undefined') {
			var promoDateObj = new Date(item.promo_enddate);
			data['promoEndDate'] = (promoDateObj.getMonth() + 1) + '/' + promoDateObj.getDate() + '/' + promoDateObj.getFullYear();
		}

		// for press release pages, include press release date
		if (typeof item.press_release_date != 'undefined') {
			var prDateObj = new Date(item.press_release_date);
			data['pressReleaseDate'] = monthNames[prDateObj.getMonth()].longname + ' ' + prDateObj.getDate() + ', ' + prDateObj.getFullYear();
		}
		return Mustache.render($resultItemTpl, data);
	}

	// display pagination
	var renderPaginationPagesNu = function (type, currentPage, totalPages) {
		var pages = '<div class="pagination">',
		    previousPage = currentPage - 1,
		    nextPage = currentPage + 1,
		    startPageCount = currentPage-1,
		    endPageCount = currentPage+1;

		if (startPageCount < 2) {
			startPageCount = 1;
			endPageCount = startPageCount + 3;
		}
		if (endPageCount > totalPages - 2) {
			endPageCount = totalPages;
			if (endPageCount - 3 > 0) {
				startPageCount = endPageCount - 3;
			}
		}

		// adds prev page when need
		if (previousPage > 0) {
			pages += '<a href="#" class="pagination-previous" data-hash="true" data-page="' + previousPage + '" aria-label="previous page">' +
				'<svg aria-hidden="true" class="svg-icon svg-icon-chevron">\n' +
				'<use xlink:href="#icon-chevron"></use>\n' +
				'</svg></a>';
		}

		// display first page in pagination when the start count is not 1
		if (startPageCount > 1) {
			pages += '<a href="#" class="pagination-link first" data-hash="true" data-page="1" aria-label="page 1">1</a>';
		}

		// display ... when there is a gap between the first page number and next
		if (startPageCount > 2) {
			pages += '<span class="pagination-spacer">&hellip;</span>';
		}

		// creates page links and marks current page
		for (var p = startPageCount; p <= endPageCount; p++) {
			var currentPageClass = '';
			if (p == currentPage) {
				currentPageClass = 'current';
			}
			pages += '<a href="#" class="pagination-link ' + currentPageClass + '" data-hash="true" data-page="' + p + '" aria-label="page ' + p + ' ' + currentPageClass + '">' + p + '</a>';
		}

		// display ... when there is a gap between the last page number and previous
		if (endPageCount < (totalPages - 1)) {
			pages += '<span class="pagination-spacer">&hellip;</span>';
		}

		// display last page in pagination when the end count is not total pages
		if (endPageCount < totalPages) {
			pages += '<a href="#" class="pagination-link last" data-hash="true" data-page="' + totalPages + '" aria-label="page ' + totalPages + '">' + totalPages + '</a>';
		}

		// adds next page when need
		if (nextPage <= totalPages) {
			pages += '<a href="#" class="pagination-next" data-hash="true" data-page="' + nextPage + '" aria-label="next page">' +
				'<svg aria-hidden="true" class="svg-icon svg-icon-chevron">\n' +
				'<use xlink:href="#icon-chevron"></use>\n' +
				'</svg></a>';
		}

		pages += '</div>';
		return pages;
	}

	//scroll to top of the page when pagination-link is clicked
	$resultsContainer.on('click','.pagination-link', function () {
		$('html,body').stop().animate({scrollTop: ($('#content').offset().top)-20 }, 500);
	});

	// when a category is checked / unchecked, reload the search results to update the view
	$categoryContainer.on('click', 'input', function(e) {
		// Set the hash to empty
		window.searchConfig.facets = {};

		$('#category-facets input[type="checkbox"]').each(function(idx, obj) {
			var $checkbox = $(obj),
			    facet = $checkbox.attr('name');

			if (!window.searchConfig.facets[facet]) {
				window.searchConfig.facets[facet] = [];
			}

			if ($checkbox.prop("checked")) {
				window.searchConfig.facets[facet].push($checkbox.attr('value'));
			}
		});

		//update the location hash to point to page 1 before reloading the results
		var params = $.hashParams();
		if (typeof params['stq'] != 'undefined') {
			location.hash = '#stq=' + DOMPurify.sanitize(params['stq'], DOMPurifyConfig) + '&stp=1'
		}

		reloadResults();
	});

	// remove all facet filter when "all categories" is clicked
	$categoryContainer.on('click', '.clear-selection', function() {
		var name = $(this).data('name');
		$('input[name=' + name + ']').prop("checked", false);
		window.searchConfig.facets[name] = [];

		reloadResults();
	});

	// initialize swiftype search plugin
	$siteSearchField.swiftypeSearch({
		perPage: '10', //not working?
		resultContainingElement: '#results-container', //target where results will be populated
		engineKey: swiftypeEngineKey, //current engine key from swiftype
		renderFunction: renderSearchResults,
		facets: { page: ['category'] },
		filters: readFilters,
		postRenderFunction: renderSummaryCategory,
		renderPaginationForType: renderPaginationPagesNu
	});

	// check to see if the stq parameter in the url
	var params = $.hashParams();
	if (typeof params['stq'] != 'undefined' && params['stq'] != '') {
		// fill the search filed with the query
		$('#site-search-field').val(DOMPurify.sanitize(params['stq'], DOMPurifyConfig)).closest('.label-stack').addClass('populated');
	} else {
		// no query, display the placeholder content
		$('#search-placeholder').removeClass('hidden');
	}
});